<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Cập nhật người dùng
          </CCardHeader>
          <CCardBody style="min-height: 300px" tag="div">
            <CForm>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Họ" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Họ" horizontal :value.sync="detailItem.firstname" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Tên" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên" horizontal :value.sync="detailItem.lastname" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Tên tài khoản" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên tài khoản" horizontal :value.sync="detailItem.username" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CInput label="Số điện thoại" horizontal :value.sync="detailItem.phone"/>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Email" horizontal :value.sync="detailItem.email" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CSelect label="Loại đơn vị" horizontal :value.sync="detailItem.loaiDonVi"
                           :options="optionsLoaiDonVi"/>
                </CCol>
                <CCol lg="6">
                  <template v-if="detailItem.loaiDonVi === 2 || detailItem.loaiDonVi === 3">
                    <ValidationProvider name="Tên đơn vị" rules="required" v-slot="{ errors, valid , touched }"
                                        v-if="detailItem.loaiDonVi === 2">
                      <BaseSelect label="Tên đơn vị" horizontal :value.sync="detailItem.donVi_Id" :is-valid="valid"
                                  :touched="touched" :invalid-feedback="errors" :options="optionsDmDonVi"/>
                    </ValidationProvider>

                    <ValidationProvider name="Tên đơn vị" rules="required" v-slot="{ errors, valid , touched }" v-else>
                      <BaseSelect label="Tên đơn vị" horizontal :value.sync="detailItem.donVi_Id" :is-valid="valid"
                                  :touched="touched" :invalid-feedback="errors" :options="optionsDmTinhThanh"/>
                    </ValidationProvider>
                  </template>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Nhóm quyền" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseSelect label="Nhóm quyền" horizontal :value.sync="detailItem.applicationRoleId"
                                :is-valid="valid"
                                :touched="touched" :invalid-feedback="errors" :options="optionsApplicationRole"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6"></CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <div role="group" class="form-group form-row">
                    <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                    <CCol sm="9" class="form-inline">
                      <CInputCheckbox label="Hoạt động" :checked.sync="detailItem.status"/>
                    </CCol>
                  </div>
                </CCol>
              </CRow>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  GET_APPLICATION_USER,
  UPDATE_APPLICATION_USER
} from '@/store/modules/application-user/actionTypes'

import { enums } from '@/shared/enums'
import { applicationRoleService } from '@/services/application-role.service'
import { dmDonViService } from '@/services/donvi.service'
import { tinhThanhService } from '@/services/tinh-thanh.service'

export default {
  name: 'ApplicationUserUpdate',
  data () {
    return {
      isLoading: false,
      pw_confirmation: '',
      optionsLoaiDonVi: enums.loaiDonVi,
      optionsDmDonVi: [],
      optionsDmTinhThanh: [],
      optionsApplicationRole: []
    }
  },
  computed: {
    ...mapGetters('applicationUser', {
      isSaving: 'isSaving',
      responseResult: 'responseResult',
      detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('applicationUser', {
      getItem: GET_APPLICATION_USER,
      updateItem: UPDATE_APPLICATION_USER
    }),
    async saveItem () {
      this.detailItem.applicationRoleId = Number(this.detailItem.applicationRoleId)
      this.detailItem.loaiDonVi = Number(this.detailItem.loaiDonVi)
      this.detailItem.donVi_Id = Number(this.detailItem.donVi_Id)
      await this.updateItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/nguoi-dung' })
      }
    },
    cancel () {
      this.$router.push({ path: '/nguoi-dung' })
    }
  },
  watch: {
    'detailItem.loaiDonVi': function (newVal, oldVal) {
      if (newVal && oldVal && newVal !== oldVal) {
        this.detailItem.donVi_Id = null
        applicationRoleService.getByLoaiDonVi(newVal).then(data => {
          this.optionsApplicationRole = data
        })
      }
    }
  },
  async mounted () {
    this.isLoading = true
    console.log(this.$route.params.id)
    await this.getItem(this.$route.params.id)
    this.optionsDmDonVi = await dmDonViService.getAll()
    this.optionsDmTinhThanh = await tinhThanhService.getAll()
    this.optionsApplicationRole = await applicationRoleService.getByLoaiDonVi(this.detailItem.loaiDonVi)
    this.isLoading = false
  }
}
</script>
